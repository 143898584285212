<template>
    <div>
        <div v-if="loading">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ item.modalType }} Address Details</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn id="info-guide" @click="infoStartGuide" fab height="4vh" width="4vh">
                    <v-icon>help</v-icon>
                </v-btn>
                <v-btn id="save-button" icon :key="addKey" :loading="loading" color="secondary" @click="saveAddress()"
                    :disabled="!item.addressLine1 ||
                        !selectedCountry">
                    <v-icon>save</v-icon>
                </v-btn>
                <v-btn id="cancel-button" icon color="danger" @click="closeModal()">
                    <v-icon>cancel</v-icon>
                </v-btn>
            </v-toolbar>
            <v-container fluid>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" lg="6" id="dialog-address-info">
                            <v-text-field outlined label="Alias" @change="addKey++" v-model="item.alias">
                            </v-text-field>
                            <v-text-field outlined label="Address Line 1*" @change="addKey++" v-model="item.addressLine1">
                            </v-text-field>
                            <v-text-field outlined label="Address Line 2" @change="addKey++" v-model="item.addressLine2">
                            </v-text-field>
                            <v-text-field outlined label="Address Line 3" @change="addKey++" v-model="item.addressLine3">
                            </v-text-field>
                            <v-text-field outlined label="Address Line 4" @change="addKey++" v-model="item.addressLine4">
                            </v-text-field>
                            <v-text-field outlined label="Postal Code" @change="addKey++" v-model="item.postalCode">
                            </v-text-field>

                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="6" id="dialog-important-info">
                            <v-autocomplete id="dialog-country" outlined v-model="selectedCountry" :items="countries"
                                placeholder="Start typing to search" @change="addKey++" item-text="name" return-object
                                item-value="name" :loading="loadingCountries" label="Country*" append-icon="flag">
                            </v-autocomplete>
                            <div v-if="manualSubdivisionsOverride || !subdivisions || subdivisions.length == 0">
                                <v-text-field :label="'Province / State'" append-icon="area_chart" outlined
                                    color="secondary" v-model="item.subdivision" :disabled="!item.countryCode"
                                    @click:append="manualSubdivisionsOverride = !manualSubdivisionsOverride">
                                </v-text-field>
                            </div>
                            <div v-else>
                                <v-autocomplete v-model="item.subdivision" :items="subdivisions" item-text="name"
                                    item-value="name" :loading="loadingSubdivisions" @change="addKey++"
                                    :disabled="!item.countryCode" append-icon="area_chart"
                                    placeholder="Start typing to search" outlined hint="hello"
                                    :label="subdivisionDescription == '' || null ? 'Province / State' : subdivisionDescription"
                                    @click:append="manualSubdivisionsOverride = !manualSubdivisionsOverride">
                                </v-autocomplete>
                            </div>

                            <div v-if="manualTownOverride || !towns || towns.length == 0">
                                <v-text-field label="Town / Nearest Town" placeholder="Enter town name" v-model="item.town"
                                    outlined append-icon="location_city" color="secondary" :disabled="!item.countryCode"
                                    @click:append="manualTownOverride = !manualTownOverride">
                                </v-text-field>
                            </div>
                            <div v-else>
                                <v-autocomplete v-model="item.town" :loading="loadingTowns" :items="towns"
                                    @change="addKey++" :v-model="townParams.search" outlined
                                    placeholder="Start typing to search" append-icon="location_city" item-text="name"
                                    item-value="name" label="Town / Nearest Town" tooltip:append="Switch to manual input"
                                    :disabled="!item.countryCode" @click:append="manualTownOverride = !manualTownOverride">
                                </v-autocomplete>
                            </div>
                            <!-- <div v-if="selectedCountry.iso2 == 'ZA'">
                                <v-autocomplete label="PPECB Office Location" v-model="item.activityPointCode" outlined
                                    dense clearable :loading="loadingActivityPoints" :items="activityPoints"
                                    item-text="name" item-value="code"></v-autocomplete>
                            </div> -->
                            <v-switch id="switch-super" label="Use this address to process documentation?"
                                v-model="item.isDocsAddress">
                            </v-switch>
                            <v-switch label="Is this an address for deliveries?" v-model="item.isDeliveryAddress">
                            </v-switch>
                            <v-switch label="Is this your HQ?" v-model="item.isHQ"></v-switch>
                            <!-- <v-autocomplete outlined label="Point Of Contact*" @change="addKey ++" v-model="item.organisationUserId">
                            </v-autocomplete>
                            <v-text-field outlined label="Point Of Contact Number*" @change="addKey ++"
                                v-model="item.pointOfContactNumber">
                            </v-text-field>
                            <v-text-field outlined label="Point Of Contact Email*" @change="addKey ++"
                                v-model="item.pointOfContactEmail">
                            </v-text-field> -->
                        </v-col>
                        <v-col></v-col>
                    </v-row>
                </v-card-text>
            </v-container>
        </v-card>

        <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Add/Edit Address Form!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
// import steps from './Steps/addressModalSteps'

export default {
    props: ['item', 'dialogVisible'],
    data: () => ({
        steps: null,
        activityPoints: [],
        addKey: 0,
        loading: false,
        loadingCountries: false,
        loadingSubdivisions: false,
        loadingTowns: false,
        countries: [],
        subdivisions: [],
        towns: [],
        selectedCountry: {},
        loadingActivityPoints: false,
        manualTownOverride: false,
        isSubdivisionsEmpty: false,
        manualSubdivisionsOverride: false,
        searchTimer: undefined,
        townParams: {
            limit: 15,
            offset: 0,
            search: ''
        },
        subdivisionDescription: 'Province / State',
        infoStart: false
    }),
    watch: {
        item(val) {
            // if(val)
            this.setSelectedCountry()
        },
        'dialogVisible': {
            async handler(val) {
                if (val) {
                    this.setSelectedCountry()
                    // if (this.item.modalType == 'Edit') {
                    //     await this.loadItem()
                    // }
                }
            }
        },
        'selectedCountry': {
            async handler(val) {
                if (val && val.iso2) {
                    console.log(val)
                    this.item.country = val.name;
                    this.item.countryCode = val.iso2;
                    this.setSubdivisionDescription();
                    this.loadSubdivisions();
                    this.loadTowns();
                }
            }
        },
        'townParams.search': {
            async handler(val) {
                val = JSON.parse(JSON.stringify(val));
                if (val) {
                    this.loadTowns();
                }
            }
        }
    },
    async created() {
        this.loadCountries()
        this.loadActivityPoints()
        await this.getSteps();
    },
    async mounted() {
        console.log(this.item);
        this.setSelectedCountry()
        this.driver = new Driver({
            animate: false
        })
    },
    methods: {
        async getSteps() {
        let steps = await this.$API.getGuideSteps({
            guideId: 'SystemComponents/AddressModal/Steps',
        });
        this.steps = steps.steps
        },
        async loadActivityPoints() {
            this.loadingActivityPoints = true
            this.activityPoints = await this.$API.getOfficeActivityPoints()
            this.loadingActivityPoints = false
        },
        async loadItem() {
            this.loading = true;
            await this.setSubdivisionDescription();
            await this.loadSubdivisions();
            await this.loadTowns();
            if (this.item.town) { if (!this.towns.some(e => e.name === this.item.town)) { this.manualTownOverride = true } }
            if (this.item.subdivision) {
                if (!this.subdivisions.some(e => e.name === this.item.subdivision)) { this.manualSubdivisionsOverride = true }
            }
            this.loading = false;
        },
        async setSelectedCountry() {
            if (this.item.countryCode) {
                this.selectedCountry = {
                    name: this.item.country,
                    iso2: this.item.countryCode
                }
            }
            // console.log(this.selectedCountry)
            this.loadItem()
            // await this.setSubdivisionDescription();
            // await this.loadSubdivisions();
            // await this.loadTowns();
        },
        async loadCountries() {
            this.loadingCountries = true;
            this.countries = await this.$API.searchCountries();
            this.countries.data.forEach(x => x.countryCode = x.iso2)
            this.countries = this.countries.data
            this.loadingCountries = false;
        },
        async loadSubdivisions() {
            if (this.item.countryCode) {
                this.loadingSubdivisions = true;
                this.subdivisions = await this.$API.getCountrySubdivisionsAdmin(this.item.countryCode)
                this.loadingSubdivisions = false;
            }

        },
        async loadTowns() {
            if (this.item.countryCode) {
                if (this.searchTimer) { clearTimeout(this.searchTimer) }
                this.searchTimer = setTimeout(async () => {
                    this.loadingTowns = true;
                    this.towns = await this.$API.getCountryTowns(this.item.countryCode, { params: this.townParams });
                    this.loadingTowns = false;
                }, 200)
            }
        },
        async setSubdivisionDescription() {
            if (this.item.countryCode) {
                let res = await this.$API.getCountrySubdivisionDescription(this.item.countryCode)
                if (res) { this.subdivisionDescription = res.type[0].toUpperCase() + res.type.substring(1) }
                else { this.subdivisionDescription = 'Province / State' }
            }

        },
        clearVariables() {
            this.manualTownOverride = false;
            this.manualSubdivisionsOverride = false;
            this.selectedCountry = {};
        },
        closeModal() {
            this.loading = true;
            this.$emit('close');
            this.clearVariables();
            this.loading = false;
        },
        async saveAddress() {
            this.loading = true;
            this.addKey++
            if (this.item.organisationId && !this.item.id) {
                let item = await this.$API.createOrganisationAddress(this.item);
                this.item.id = item.id
            } else if (this.item.id) {
                await this.$API.updateOrganisationAddress(this.item);
            }
            this.$emit('save', this.item);
            this.clearVariables();
            this.loading = false;
        },
        infoStartGuide() {
            if (this.infoStart) {
                this.infoStart = false;
            } else {
                this.infoStart = true;
            }
        },
        startGuide(e) {
            this.infoStart = false
            e.stopPropagation();
            this.driver.defineSteps(this.steps)
            this.driver.start()
        },
    }
}
</script>