<template>
    <div v-if="!loading">
        <v-card :loading="loading">
            <v-container fluid>
                <v-row>
                    <v-col cols="12" sm="6" md="6" lg="4">
                        <v-card outlined height="100%">
                            <v-subheader>Company Information
                                <v-spacer></v-spacer>
                                <v-chip class="ma-2" outlined :color="item.isActive ? 'success' : 'danger'"
                                    @click="item.isActive = !item.isActive">
                                    <v-icon small left>{{ item.isActive ? 'check' : 'close' }}</v-icon>
                                    {{ item.isActive ? 'Active' : 'Inactive' }}
                                </v-chip>
                            </v-subheader>
                            <v-card-text>
                                <v-text-field dense v-model="item.name" label="Registered Company Name" required outlined
                                  persistent-placeholder prepend-inner-icon="business">
                                </v-text-field>
                                <v-text-field dense v-model="item.alias" label="Display Name / Alias" required outlined
                                  persistent-placeholder prepend-inner-icon="business">
                                </v-text-field>
                                <v-row>
                                    <v-col>
                                        <v-autocomplete dense v-model="selectedCountry" :items="countryNames"
                                            item-text="name" item-value="name" return-object label="Country" outlined>
                                            <template v-slot:selection="data">
                                                <v-avatar size="32" class="mr-2" v-if="data.item">
                                                    <v-img v-if="data.item.countryCode" contain
                                                        :src="`https://cdn.loglive.io/flags/4x3/${data.item.countryCode.toLowerCase()}.svg`">
                                                    </v-img>
                                                </v-avatar>
                                                <span v-if="data.item">
                                                    {{ data.item.name }}
                                                </span>
                                            </template>
                                            <template v-slot:item="data">
                                                <v-list-item-avatar>
                                                    <v-img contain v-if="data.item.countryCode"
                                                        :src="`https://cdn.loglive.io/flags/4x3/${data.item.countryCode.toLowerCase()}.svg`">
                                                    </v-img>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                        </v-autocomplete>
                                        <v-text-field dense v-model="item.taxNumber" label="Tax Number / UTR Number"
                                            prepend-inner-icon="numbers" required outlined>
                                        </v-text-field>
                                        <v-text-field dense v-model="item.customsCode" class="text-field-label-top"
                                            prepend-inner-icon="numbers" label="Customs Code" required outlined>
                                        </v-text-field>
                                        <v-text-field dense v-model="item.DALRRDRefNumber"
                                            v-if="selectedCountry && selectedCountry.countryCode == 'ZA'"
                                            prepend-inner-icon="numbers" label="DALRRD Ref. Number" required outlined>
                                        </v-text-field>
                                        <v-text-field dense v-model="item.registrationNumber" prepend-inner-icon="numbers"
                                            label="Company Reg. Number" required outlined>
                                        </v-text-field>
                        
                                       
                                        <v-text-field persistent-placeholder dense hide-details
                                          v-if="selectedCountry && (selectedCountry.regionCode == 'EUN' || selectedCountry.countryCode == 'GB')" prepend-inner-icon="numbers"
                                          v-model="item.sicCode" label="SIC Code" required outlined>
                                        </v-text-field>

                                        <v-checkbox dense v-model="item.isGroup" label="Is this Company part of a Group?"
                                            outlined>
                                        </v-checkbox>
                                        <v-checkbox dense v-model="item.apeStatus" label="Is this an APE Company?" outlined>
                                        </v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <v-text-field :key="logCodeKey" persistent-placeholder
                                            v-model="item.logliveCode" label="Loglive Code"
                                            prepend-inner-icon="business" outlined
                                            :disabled="item.logliveCode" counter>
                                        </v-text-field>
                                        <v-text-field dense v-model="item.companyType" label="Company Type" required
                                            prepend-inner-icon="business" outlined>
                                        </v-text-field>
                                        <v-text-field dense v-model="item.vatNumber" label="Vat Number"
                                            prepend-inner-icon="numbers" required outlined>
                                        </v-text-field>
                                        <v-text-field dense v-if="selectedCountry && selectedCountry.countryCode == 'ZA'"
                                            v-model="item.cbrId" label="CBR ID Number" required outlined
                                            prepend-inner-icon="numbers">
                                        </v-text-field>
                                        <v-text-field dense v-if="selectedCountry && selectedCountry.countryCode == 'ZA'"
                                            prepend-inner-icon="numbers" v-model="item.nNumber" label="PPECB N-Number" required
                                            outlined>
                                        </v-text-field>   <v-text-field dense v-if="selectedCountry && selectedCountry.countryCode == 'ZA'"
                                            prepend-inner-icon="numbers" v-model="item.solasAccreditationNo" label="SOLAS Accreditation No" required
                                            outlined>
                                        </v-text-field>
                                        <v-text-field dense v-if="selectedCountry && selectedCountry.countryCode == 'ZA'"
                                            prepend-inner-icon="numbers" v-model="item.solasAccreditationUser" label="SOLAS Accreditation Person" required
                                            outlined>
                                        </v-text-field>
                                        <v-card outlined v-if="selectedCountry && selectedCountry.countryCode == 'ZA'">
                                        <v-subheader>UCR</v-subheader>
                                        <v-spacer></v-spacer>
                                        <v-list style="padding:16px">
                                            <v-select dense v-model="item.ucrEntityType" v-if="selectedCountry && selectedCountry.countryCode == 'ZA'" :items="['Customs Code', 'Tax Code', 'Company Register Number', 'ID / Passport Number']" label="Entity Type" required outlined>
                                            </v-select>
                                            <v-select dense v-model="item.ucrRefType" v-if="selectedCountry && selectedCountry.countryCode == 'ZA'" :items="['Invoice', 'Purchase Order', 'Contract', 'Delivery','Other','Customs Generated','Declarant Generated']" label="Reference Type" required outlined>
                                            </v-select>
                                            <v-select dense v-model="item.ucrScope" v-if="selectedCountry && selectedCountry.countryCode == 'ZA'" :items="['Single', 'Multiple']" label="Scope" required outlined>
                                            </v-select>
                                        </v-list>
                                        </v-card>

                                        <v-text-field persistent-placeholder dense
                                          v-if="selectedCountry && (selectedCountry.regionCode == 'EUN' || selectedCountry.countryCode == 'GB')" prepend-inner-icon="numbers"
                                          v-model="item.xiEori" label="XI EORI" outlined>
                                        </v-text-field>
                                        <v-text-field persistent-placeholder dense
                                          v-if="selectedCountry && (selectedCountry.regionCode == 'EUN' || selectedCountry.countryCode == 'GB')" prepend-inner-icon="numbers"
                                          v-model="item.euEori" label="EU EORI" outlined>
                                        </v-text-field>
                                        <v-text-field persistent-placeholder dense
                                          v-if="selectedCountry && (selectedCountry.regionCode == 'EUN' || selectedCountry.countryCode == 'GB')" prepend-inner-icon="numbers"
                                          v-model="item.gbEori" label="GB EORI" outlined>
                                        </v-text-field>
                                        <v-text-field dense v-if="item.shippingLine" v-model="item.scacCode" class="text-field-label-top"
                                            prepend-inner-icon="numbers" label="SCAC Code" required outlined>
                                        </v-text-field>
                                    </v-col>
                                </v-row>

                            </v-card-text>
                            <v-divider></v-divider>
                            <v-subheader>Contact Information</v-subheader>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-text-field dense v-model="item.emailAddress" prepend-inner-icon="mail"
                                            label="Contact Email Address" required outlined>
                                        </v-text-field>
                                        <v-text-field dense v-model="item.emailAddressAlt" prepend-inner-icon="mail"
                                            label="Alt Contact Email Address" required outlined>
                                        </v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field dense v-model="item.telephoneNumber" label="Telephone Number" required
                                            outlined prepend-inner-icon="phone">
                                        </v-text-field>
                                        <v-text-field dense v-model="item.telephoneNumberAlt" label="Alt Telephone Number"
                                            required outlined prepend-inner-icon="phone">
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="4">
                        <v-card outlined class="scroll" height="75vh">
                            <v-subheader>Listed Addresses</v-subheader>
                            <v-list>
                                <v-list-item @click="addAddress">
                                    <v-list-item-action>
                                        <v-icon>add</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>Add Address</v-list-item-title>
                                </v-list-item>
                                <v-list-item v-for="address in item.organisationAddresses" :key="address.id">
                                    <v-list-item-content>
                                        <div style="margin-bottom:10px;">
                                            <u style="font-size: 14px;">{{ address.alias }}</u>
                                            <v-icon right v-if="address.isHQ" color="success">high_quality
                                            </v-icon>
                                            <v-icon right v-if="address.isDeliveryAddress" color="success">
                                                local_shipping</v-icon>
                                            <v-icon right v-if="address.isDocsAddress" color="success">
                                                description</v-icon>
                                        </div>
                                        <span v-if="address.addressLine1" style="font-size: 12px">{{ address.addressLine1
                                        }}</span>
                                        <span v-if="address.addressLine2" style="font-size: 12px">{{ address.addressLine2
                                        }}</span>
                                        <span v-if="address.addressLine3" style="font-size: 12px">{{ address.addressLine3
                                        }}</span>
                                        <span v-if="address.addressLine4" style="font-size: 12px">{{ address.addressLine4
                                        }}</span>
                                        <span v-if="address.town" style="font-size: 12px">{{ address.town }}</span>
                                        <span v-if="address.subdivision" style="font-size: 12px">{{ address.subdivision
                                        }}</span>
                                        <span v-if="address.country" style="font-size: 12px">{{ address.country }}</span>
                                        <span style="font-size: 12px">{{ address.postalCode }}</span>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-row>
                                            <v-btn icon @click="editAddress(address)">
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                            <v-btn icon color="danger" @click="removeAddress(address)">
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>

                        </v-card>
                    </v-col>

                    <v-col cols="12" sm="6" md="6" lg="4">
                        <!-- <v-btn color="edit" class="ma-2" absolute @click="updateOrganisation()">
                                <v-icon left>save</v-icon>Save
                            </v-btn> -->
                        <v-card outlined class="mx-auto" height="75vh">
                            <v-card-subtitle>Company Image</v-card-subtitle>
                            <v-container fluid>
                                <v-row dense>
                                    <v-col cols="12" sm="6" md="6" lg="6">
                                        <div v-if="loadingLogo">
                                            <v-skeleton-loader class="mx-auto" max-width="max" type="card"
                                                transition="fade-transition"></v-skeleton-loader>
                                        </div>
                                        <div v-else>
                                            <v-card>
                                                <v-toolbar flat><v-card flat><v-card-subtitle
                                                            class="pl-0">Logo</v-card-subtitle></v-card>
                                                    <v-spacer></v-spacer>
                                                    <!-- <v-file-input class="d-flex flex-row-reverse" dense hide-input
                                                        @change="logoChange"
                                                        :prepend-icon="item.logo ? 'edit' : 'upload_file'">
                                                    </v-file-input> -->
                                                    <v-file-input class="d-flex flex-row-reverse" dense hide-input
                                                        @change="logoChange"
                                                        :prepend-icon="item.logo ? 'edit' : 'upload_file'"
                                                        accept=".png, .jpeg, .jpg">
                                                    </v-file-input>
                                                </v-toolbar>
                                                <v-img v-if="item.logo" :src="item.logo" height="200px" contain>
                                                </v-img>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6" lg="6">
                                        <div v-if="loadingIcon">
                                            <v-skeleton-loader class="mx-auto" max-width="max" type="card"
                                                transition="fade-transition"></v-skeleton-loader>
                                        </div>
                                        <div v-else>
                                            <v-card class="mx-auto">
                                                <v-toolbar flat><v-card flat><v-card-subtitle
                                                            class="pl-0">Icon</v-card-subtitle></v-card>
                                                    <v-spacer></v-spacer>
                                                    <v-file-input class="d-flex flex-row-reverse" dense hide-input
                                                        @change="iconChange"
                                                        :prepend-icon="item.icon ? 'edit' : 'upload_file'">
                                                    </v-file-input>
                                                </v-toolbar>
                                                <v-img v-if="item.icon" :src="item.icon" height="200px" contain>
                                                </v-img>
                                            </v-card>
                                        </div>

                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-divider></v-divider>
                            <v-card-subtitle>Network Functions</v-card-subtitle>
                            <v-card-text>
                                <v-chip class="ma-2" outlined @click="(item.bank = !item.bank)"
                                    :color="item.bank ? 'success' : 'grey'">Bank
                                    <v-icon small right>account_balance</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(item.buyer = !item.buyer)"
                                    :color="item.buyer ? 'success' : 'grey'">Buyer
                                    <v-icon small right>shopping_cart</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(item.coldstore = !item.coldstore)"
                                    :color="item.coldstore ? 'success' : 'grey'">
                                    Coldstore <v-icon small right>store</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(item.consignee = !item.consignee)"
                                    :color="item.consignee ? 'success' : 'grey'">
                                    Consignee <v-icon small right>call_received</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(item.forwarder = !item.forwarder)"
                                    :color="item.forwarder ? 'success' : 'grey'">
                                    Forwarder <v-icon small right>fast_forward</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(item.notifyParty = !item.notifyParty)"
                                    :color="item.notifyParty ? 'success' : 'grey'">
                                    Notify Party <v-icon small right>notifications_active</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(item.producer = !item.producer)"
                                    :color="item.producer ? 'success' : 'grey'">
                                    Producer <v-icon small right>factory</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(item.shipper = !item.shipper)"
                                    :color="item.shipper ? 'success' : 'grey'">
                                    Shipper <v-icon small right>north_east</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(item.shippingLine = !item.shippingLine)"
                                    :color="item.shippingLine ? 'success' : 'grey'">
                                    Shipping Line <v-icon small right>directions_boat</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(item.governingBody = !item.governingBody)"
                                    :color="item.governingBody ? 'success' : 'grey'">
                                    Governing Body <v-icon small right>gavel</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(item.pluginProvider = !item.pluginProvider)"
                                    :color="item.pluginProvider ? 'success' : 'grey'">
                                    Plugin Provider <v-icon small right>extension</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(item.transporter = !item.transporter)"
                                    :color="item.transporter ? 'success' : 'grey'">
                                    Transporter <v-icon small right>local_shipping</v-icon>
                                </v-chip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip v-on="on" class="ma-2" outlined @click="(item.vasProvider = !item.vasProvider)"
                                            :color="item.vasProvider ? 'success' : 'grey'">
                                            VAS Provider <v-icon small right>settings_suggest</v-icon>
                                        </v-chip>
                                    </template>
                                    <span>Value Added Service Provider</span>
                                </v-tooltip>
                            </v-card-text>

                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-btn color="edit" elevation="2" fab fixed bottom right style="text-transform: none;" class="saveButton"
                @click="updateOrganisation()">
                <v-icon>save</v-icon>
            </v-btn>
            <v-btn color="danger" elevation="2" fab fixed bottom right style="text-transform: none;" class="deleteButton"
                @click="removeOrganisation()">
                <v-icon>delete</v-icon>
            </v-btn>
        </v-card>

        <!-- Dialog to manage Addresses -->
        <v-dialog v-model="addressModal" persistent width="800" :fullscreen="$vuetify.breakpoint.xs">
            <AddressModalVue :item="addressItem" @addKey="addressKeyPlus()" @close="closeAddressModal()" @save="saveAddress"
                transition="scale-transition" :dialogVisible="addressModal" />
        </v-dialog>
    </div>

    <div v-else style="height: 90vh; padding-top: 0; margin-top: 0" class="text-center">
        <valhalla-loading />

    </div>
</template>

<script>
// import gmapApi from "vue2-google-maps"
import AddressModalVue from "../SystemComponents/AddressModal.vue"
export default {
    props: ['orgId'],
    components: {
        AddressModalVue
    },
    data: () => ({
        addressModal: false,
        addressItem: {},
        addressKey: 0,
        logoList: [],
        iconList: [],
        loadingIcon: false,
        loadingLogo: false,
        loading: false,
        center: {
            lng: 18.427059,
            lat: -33.919473,
        },
        zoom: 12,
        key: 0,
        countries: {
            total: 0,
            data: [],
        },
        countryNames: [],
        selectedCountry: { name: '' },
        logCodeKey: 0,
    }),
    // computed: {
    //     google: gmapApi,
    // },
    watch: {
        'orgId': {
            immediate: true,
            handler(value) {
                if (value) {
                    this.loadOrganisation(value)
                }
            }
        },
        'selectedCountry': {
            async handler(val) {
                if (val) {
                    this.item.country = val.name;
                    this.item.countryCode = val.countryCode;
                }
            }
        },
    },
    methods: {
        async loadOrganisation(id) {
            this.loading = true
            this.item = await this.$API.getOrganisationAdmin(id);
            console.log(this.item);
            await this.loadCountries();
            this.loading = false
        },
        async updateOrganisation() {
            this.loading = true
            // this.item.logliveCode = this.item.countryCode + this.item.logliveCode
            this.item.fixedCode = true
            await this.$API.updateOrganisationAdmin(this.item)
            this.$message.success('Successfully updated!')
            this.loading = false
            this.$router.back()
        },
        async loadCountries() {
            this.countries = await this.$API.searchCountries();
            for (let i = 0; i < this.countries.data.length; i++) {
                this.countryNames.push({
                    name: this.countries.data[i].name,
                    countryCode: this.countries.data[i].code
                })
            }
            if (this.item.country && this.item.countryCode) {
                this.selectedCountry = {
                    name: this.item.country,
                    countryCode: this.item.countryCode
                }
            }
        },
        // async logoChange(file) {
        //     this.loadingLogo = true;
        //     let url = await URL.createObjectURL(file);
        //     this.toDataUrl(url, (data) => {
        //         this.submitUpload({
        //             name: file.name,
        //             data: data,
        //             type: file.type,
        //         });
        //     });
        //     this.loadingLogo = false;
        // },

        // async iconChange(file) {
        //     this.loadingIcon = true;
        //     let url = await URL.createObjectURL(file);
        //     this.toDataUrl(url, (data) => {
        //         this.submitIconUpload({
        //             name: file.name,
        //             data: data,
        //             type: file.type,
        //         });
        //     });
        //     this.loadingIcon = true;
        // },

        async logoChange(file) {
            this.loadingLogo = true;
            this.logoKey++
            let url = await URL.createObjectURL(file);
            this.checkImageDimensions(url, (width, height) => {
                if (!(width == 192 && height == 192)) {
                this.$message.error(`Logo must be 192x192px, currently ${width}x${height}`);
                this.logoKey++
                this.loadingLogo = false
                return;
                }
                else {
                this.toDataUrl(url, (data) => {
                    this.submitUpload({
                    name: file.name,
                    data: data,
                    type: file.type,
                    });
                });
                }
            });
        },
        async iconChange(file) {
            this.loadingIcon = true;
            this.iconKey++
            let url = await URL.createObjectURL(file);
            this.checkImageDimensions(url, (width, height) => {
                if (!(width == 64 && height == 64)) {
                this.$message.error(`Icon must be 64x64px, currently ${width}x${height}`);
                this.iconKey++
                this.loadingIcon = false
                return;
                }
                else {
                this.toDataUrl(url, (data) => {
                    this.submitIconUpload({
                    name: file.name,
                    data: data,
                    type: file.type,
                    });
                });
                }
            })
        },

        toDataUrl(url, callback) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function () {
                var reader = new FileReader();
                reader.onloadend = function () {
                    callback(reader.result);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open("GET", url);
            xhr.responseType = "blob";
            xhr.send();
        },
        async submitUpload(obj) {
            this.loadingLogo = true;
            let res = await this.$API.uploadAdminLogo(obj, this.item.id);
            this.item.logo = res.logo;
            this.$message.success('Logo update successfull!');
            this.loadingLogo = false;
        },
        async submitIconUpload(obj) {
            this.loadingIcon = true;
            let res = await this.$API.uploadAdminIcon(obj, this.item.id);
            this.item.icon = res.icon;
            this.$message.success('Icon update successfully!');
            this.loadingIcon = false;
        },
        addressKeyPlus() {
            this.addressKey++
        },
        addAddress() {
            this.addressItem = {
                modalType: "Add",
                organisationId: this.item.id,
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                addressLine4: "",
                country: this.selectedCountry.name,
                countryCode: this.selectedCountry.countryCode,
                subdivision: "",
                town: "",
                postalCode: "",
            };
            this.addressModal = true;
        },
        async saveAddress(obj) {
            if (!this.item.organisationAddresses.some(e => e.id === obj.id)) { this.item.organisationAddresses.push(obj) }
            this.addressModal = false;
            this.addressItem = {};
            this.$message.success('Address saved successfully!')
        },
        async editAddress(item) {
            this.addressItem = item;
            if (!this.addressItem.country) {
                this.addressItem.country = this.selectedCountry.name
                this.addressItem.countryCode = this.selectedCountry.countryCode
            }
            this.addressItem.modalType = "Edit";
            this.addressModal = true;
        },
        closeAddressModal() {
            this.addressModal = false;
            this.addressItem = {}
        },
        removeOrganisation() {
            this.$confirm('Are you sure you want to delete this Organisation?', 'Confirm', {
                center: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'plain'
            }).then(async () => {
                this.loading = true
                await this.$API.updateOrganisationAdmin({
                    id: this.item.id,
                    isActive: false,
                    isDeleted: true
                })
                this.$router.back()
                this.loading = false
                this.$message({
                    type: 'success',
                    message: 'Successfully deleted!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        removeAddress(item) {
            this.$confirm(
                `Are you sure you want to remove this address?`,
                "Confirm",
                {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                }
            ).then(async () => {
                this.loading = true
                await this.$API.updateOrganisationAdminAddress({
                    id: item.id,
                    isActive: false,
                    isDeleted: true,
                });
                this.item.organisationAddresses.splice(
                    this.item.organisationAddresses.indexOf(item),
                    1
                );
                this.$message({
                    type: "success",
                    message: "Successfully deleted!",
                });
                this.loading = false
            }).catch(() => {
                this.$message({
                    type: "",
                    message: "Delete canceled",
                });
            });
        },
    }
}
</script>

<style scoped>
.scroll {
    overflow-y: scroll
}

.deleteButton {
    top: 1;
    margin: -10px 110px 60px 0;
}

.saveButton {
    top: 1;
    margin: -10px 50px 60px 0;
}

.upload-demo {
    width: 178px;
    height: 178px;
}
</style>